import Select from '@/components/Forms/Inputs/Select'
import { VIDEO_POPUP_ID } from '@/components/Popup/config'
import { MarketCodeContext } from '@/context/MarketContext'
import { marketCodeManager } from '@/recoil/marketCode'
import { popupDataManager, popupManager } from '@/recoil/popup'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import icon from '@/images/play-icon.svg'

import Button from '@/components/Button'

import imageStar from '@/images/wine-selection-star.svg'

import {
  Actions,
  Container,
  Recommendation,
  RecommendationLink,
  RecommendationSpecial,
  RecommendationSpecialLabel,
  RecommendationSpecialLogo,
  Subtitle,
  Title,
  VideoCta,
  VideoCtaIcon,
  VideoCtaLabel,
  Wrapper,
  Wysiwyg
} from './style'

const Text = ({
  title = '',
  titleTag = 'h3',
  slideSelector = null,
  subtitle = '',
  subtitleTag = 'span',
  text = '',
  actions = [],
  insertAfterText = [],
  active = {},
  gtm = () => {}
}) => {
  const [isVisitUs, setIsVisitUs] = useState(false)

  const setPopup = useSetRecoilState(popupManager)
  const setPopupData = useSetRecoilState(popupDataManager)
  const pageMarketCode = useContext(MarketCodeContext)
  const marketCode = useRecoilValue(marketCodeManager)

  const URLMarketCode = typeof window !== 'undefined' ? marketCode : pageMarketCode

  const handleClick = () => {
    if (active && active.source) {
      gtm('Play video', title)
      setPopup(VIDEO_POPUP_ID)
      setPopupData(active)
    }
  }

  useEffect(() => {
    setIsVisitUs(window.location.pathname.includes('visit-us'))
  }, [])

  return (
    <Container>
      { !!subtitle && (<Subtitle { ...{ as: subtitleTag } }>{ subtitle }</Subtitle>) }
      { !!title && (<Title { ...{ as: titleTag } } spaceY={ isVisitUs }>{ title }</Title>) }
      { slideSelector && <Select
        label={ slideSelector.label }
        list={ slideSelector.items }
        onChange={ slideSelector.onchange }
        onKeyDown={ (e) => e.key === 'Enter' && slideSelector.onchange }
        name='filterSelect'
        withDropDownIcon
        isSlideSelector
                         /> }
      { !!text && (<Wysiwyg>{ text }</Wysiwyg>) }
      { !!insertAfterText && insertAfterText}
      {
        !!actions.length && (
          <Actions className={ actions.length > 1 ? 'many' : 'one' }>
            { actions.map((action) => action) }
          </Actions>
        )
      }
      { slideSelector && active.recommendation.map((item, i) => {
        const URL = `/${URLMarketCode}${item.url}`

        if (item.type === 'wine_club_dedicated_wines') {
          return (
            <Recommendation key={ i }>
              {item.title}

              {
                !!item.special && (
                  <RecommendationSpecial>
                    <RecommendationSpecialLogo loading='lazy' src={ imageStar } alt='logo-star' />
                    <RecommendationSpecialLabel>
                      a special release
                    </RecommendationSpecialLabel>
                  </RecommendationSpecial>
                )
              }
            </Recommendation>
          )
        }

        return (
          <RecommendationLink
            key={ i }
            href={ URL }
            target='_blank'
            rel='noopener noreferrer'
          >
            {item.title}
          </RecommendationLink>
        )
      })}
      <Wrapper>
        { slideSelector && active && <Button key={ 1 } to={ active.url } target='_blank'>{active.ctaLabel}</Button>}
        { slideSelector && active.source &&
          <VideoCta key={ 2 } onClick={ handleClick } target='_blank'>
            <VideoCtaIcon loading='lazy' src={ icon } alt='play icon' />
            <VideoCtaLabel>{active.videoLabel}</VideoCtaLabel>
          </VideoCta>}
      </Wrapper>
    </Container>
  )
}

Text.propTypes = {
  title: PropTypes.string,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  subtitle: PropTypes.string,
  subtitleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'span']),
  text: PropTypes.string,
  actions: PropTypes.array,
  insertAfterText: PropTypes.array,
  gtm: PropTypes.func
}

export default Text
